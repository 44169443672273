@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #2e2e34;
  /* background-color: #fcfcff; */
  /* background-color: #f5f3ff; */
  /* background-color: #262726; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.tokens-scroll::-webkit-scrollbar {
  width: 0.4em;
}

.tokens-scroll {
  scroll-margin-top: 10px;
}

/* #tokens::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  border-radius: 0.75rem;
} */

.tokens-scroll::-webkit-scrollbar-thumb {
  background-color: darkgray;
  /* outline: solid black; */
  border-radius: 1rem;
  background-clip: padding-box;
}

.homeButton:hover {
  background: rgba(255, 255, 255, 0.4);
}

/* #tokens::-webkit-scrollbar-corner {
  border-radius: 0.75rem;
} */
